import request from '@/utils/request';
import { ReqFilterTable } from '@/interface';
export function UserGroupSelect(){
  return request({
    url: '/appIb/userGroup/getGroup',
    method: 'get'
  });
}
// 列表
export function getUserGroupListApi(data:ReqFilterTable<any>){
  return request({
    url: '/appIb/userGroup/datas',
    method: 'post',
    data
  });
}
// 列表删除
export function userGroupDeleteApi(id:number){
  return request({
    url: '/appIb/userGroup/delete',
    method: 'get',
    params:{id}
  });
}
// 位移
export function userGroupMoverOrder(data:any){
  return request({
    url: '/appIb/userGroup/moverOrder',
    method: 'post',
    data
  });
}
// 新增组别
export function addGroupApi(data:any){
  return request({
    url: '/appIb/userGroup/add',
    method: 'post',
    data
  });
}
// 更新组别
export function editGroupApi(data:any){
  return request({
    url: '/appIb/userGroup/update',
    method: 'post',
    data
  });
}
// 获取组别用户列表 全部用户
export function getAllGroupPepoleListApi(data:any){
  return request({
    url: '/appUser/notGroupUserList',
    method: 'post',
    data
  });
}
// 获取组别用户列表 当前用户
export function getGroupPepoleListApi(id:number|string){
  return request({
    url: '/appUser/groupUserList',
    method: 'get',
    params:{groupId:id}
  });
}
// 回显资源
export function getResourceByGroupApi(id:number|string){
  return request({
    url: '/appIb/userGroup/getResourceByGroup',
    method: 'get',
    params:{groupId:id}
  });
}
// 组别添加资源
export function addResourceToGroupApi(data:any){
  return request({
    url: '/appIb/userGroup/addResourceToGroup',
    method: 'post',
    data
  });
}
// 组别添加用户
export function addUserToGroupApi(data:any){
  return request({
    url: '/appIb/userGroup/addUserToGroup',
    method: 'post',
    data
  });
}