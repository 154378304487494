import { ReqFilterTable } from '@/interface';
import request from '@/utils/request';

export function getUserList(data: ReqFilterTable<any>){
  return request({
    url: '/appUser/ibDatas',
    method: 'post',
    data
  })
}
export function getResourseAllTree(){
  return request({
    url: '/appSystem/resource/getResourceTree',
    method: 'get'
  })
}