


























































import { Vue, Component } from 'vue-property-decorator'
import PaginationBox from '@/view/admin/components/pagination-box.vue'
import HeadMenuBox from '@/view/admin/components/head-menu-box.vue'
import tableList from '@/components/tableList.vue';

import { ReqFilterTable } from '@/interface';
import { getUserList } from '@/api/system_api'
import { UserItem } from '@/interface/system';
import { UserGroupSelect } from '@/api/user_group_api';
@Component({
  components: {
    PaginationBox,
    HeadMenuBox,
    tableList
  }
})
export default class UserManagement extends Vue{
  // 性别
  get checkGender(){
    let name:any
    return (gender:any)=>{
      if(gender===null){
        name='';
      }else{
        if(gender===1){
          name=this.$t('female');
        }else if(gender===0){
          name=this.$t('male');
        }
      }
      return name;
    }
  }
  groupTypeList = [];
  reqFilterTable: ReqFilterTable<{fkGroupId:string, keyword: string}> = { 
    currentPage: 1,
    showCount: 10,
    totalResult: 0,
    data: {
      fkGroupId: '',
      keyword: '',
    }
  };
  list: UserItem[] = [];
  myPermission: string[] = [];
  get isPermission(){
    return (str: string)=>{
      return this.myPermission.indexOf(str) >= 0;
    }
  }
  mounted(){
    this.myPermission = this.$route.meta.permission;
    this.getList();
    this.getGroupSelect();
  }
  handleSizeChange(size: number){
    this.reqFilterTable.showCount = size;
    this.reqFilterTable.currentPage = 1;
    this.getList();
  }
  handleCurrentChange(page: number){
    this.reqFilterTable.currentPage = page;
    this.getList();
  }
  createNewPerson(){
    this.$router.push({name:'adminUserAdd'})
  }
  async getList(){
    const ret = await getUserList(this.reqFilterTable);
    if (ret.data.code == 1003 && ret.data.datas && ret.data.datas.length){
      this.list = ret.data.datas;
      this.reqFilterTable.totalResult = ret.data.page.totalResult
    } else {
      this.list = [];
      this.reqFilterTable.totalResult=0
    }
  }
  async getGroupSelect(){
    const ret = await UserGroupSelect();
    if(ret.data.code == 1003 && ret.data.data && ret.data.data.length){
      this.groupTypeList = ret.data.data;
    }
  }
  search() {
    this.reqFilterTable.currentPage = 1;
    this.getList();
  }
  reset(){
    this.reqFilterTable.currentPage = 1;
    this.reqFilterTable.showCount = 10;
    this.reqFilterTable.data.fkGroupId = '';
    this.reqFilterTable.data.keyword = '';
    this.getList();
  }
  refresh(){
    this.getList();
  }
}
