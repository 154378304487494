var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base_container_main_scroll"},[_c('head-menu-box',{attrs:{"create-show":_vm.isPermission('systemConfigUserAdd')},on:{"search":_vm.search,"reset":_vm.reset,"refresh":_vm.refresh,"create":_vm.createNewPerson},scopedSlots:_vm._u([{key:"searchMenu",fn:function(){return [_c('el-select',{staticClass:"select",attrs:{"size":"small","placeholder":_vm.$t('pleaseSelectUserGroup'),"clearable":""},model:{value:(_vm.reqFilterTable.data.fkGroupId),callback:function ($$v) {_vm.$set(_vm.reqFilterTable.data, "fkGroupId", $$v)},expression:"reqFilterTable.data.fkGroupId"}},_vm._l((_vm.groupTypeList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.groupName,"value":item.id}})}),1),_c('el-input',{staticClass:"input",attrs:{"size":"small","placeholder":_vm.$t('searchKeyWord')},model:{value:(_vm.reqFilterTable.data.keyword),callback:function ($$v) {_vm.$set(_vm.reqFilterTable.data, "keyword", $$v)},expression:"reqFilterTable.data.keyword"}})]},proxy:true}])}),_c('div',[_c('table-list',{attrs:{"listData":_vm.list,"headData":[
        {prop: 'groupName', label: _vm.$t('userGroup'), width: 120, slot: 'groupName', show: true },
        {prop: 'id', label: _vm.$t('userId'), width: 120, slot: 'id', show: true },
        {prop: 'loginId', label: _vm.$t('loginAccount'), width: 140 },
        {prop: 'company', label: _vm.$t('insititution'), width: 140 },
        {prop: '', label: ((_vm.$t('userName')) + "（" + (_vm.$t('userEnglishName')) + "）"), width: 250, slot: 'name', show: true},
        {prop: 'gender', label: _vm.$t('gender'), width: 80, slot: 'gender', show: true, align: 'center' },
        {prop: 'gmtCreateUser', label: _vm.$t('createPerson'), width: 140 },
        {prop: 'gmtCreate', label: _vm.$t('createTime'), width: 180 } ],"reqFilterTable":_vm.reqFilterTable},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"groupName",fn:function(ref){
      var row = ref.row;
return _vm._l((row.userGroupDtoList),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item.groupName)+" ")])})}},{key:"id",fn:function(ref){
      var row = ref.row;
return [(_vm.isPermission('systemConfigUserView'))?_c('router-link',{staticClass:"pointer",attrs:{"to":{name: 'adminUserDetail', params: {id: row.id}}}},[_vm._v(_vm._s(row.id))]):_c('span',[_vm._v(_vm._s(row.id))])]}},{key:"name",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name)+" "+_vm._s(row.nameEn || ''))])]}},{key:"gender",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.checkGender(row.gender)))])]}},{key:"opera",fn:function(scope){return [_c('icon-button',{attrs:{"showCheck":true},on:{"handleCheck":function($event){return _vm.handleCheck(scope.row)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }